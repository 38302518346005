export const validateCpf = (cpf: string) => {
  cpf = cpf.replace(/\D/g, '');
  if(cpf.toString().length !== 11 || /^(\d)\1{10}$/.test(cpf)) return false;
  var result = true;
  [9,10].forEach(function(j){
      var soma = 0, r = 0;
      cpf.split(/(?=)/).splice(0,j).forEach(function(e, i){
          soma += parseInt(e) * ((j+2)-(i+1));
      });
      r = soma % 11;
      r = (r <2)?0:11-r;
      if(r.toString() !== cpf.substring(j, j+1)) result = false;
  });
  return result;
}

export const isValidEmail = function (email: string) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export const isValidPhone = function (phone: string) {
  phone = phone.replace(/\D/g, '');
  const re = /^((1[1-9])|([2-9][0-9]))?((3[0-9]{3}[0-9]{4})|(9[0-9]{3}[0-9]{5}))$/;
  return re.test(phone);
}