import { IAction, IUserState } from "./reducer"

export function clearUser(): IAction {
  return {
    type: '@user/CLEAR_USER',
    user: {
      name: '',
      avatar: '',
      logged: false,
      isAdmin: false,
      isAssemblyReady: false
    }
  }
}

export function storeUser(user: IUserState): IAction {
  return {
    type: '@user/STORE_USER',
    user
  }
}