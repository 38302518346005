import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import LoginFactory from '../factory/LoginFactory';
import { finishRequest, startRequest } from '../store/modules/loading/actions';
import { store } from '../store/store';
import { refreshToken } from '../services/login'

const api = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_ENDPOINT,
  timeout: 3000
});

api.interceptors.request.use(
  async (config: AxiosRequestConfig) => {
    if (LoginFactory.isLogged()) {
      config.headers.authorization = `Bearer ${LoginFactory.getAccessToken()}`;
    }

    return config;
  },
  (error: AxiosError) => {
    Promise.reject(error);
  }
);

api.interceptors.response.use(
  function (response) {
    return response;
  }, 
  async function (error) {
    if (error.response) {
      switch (error.response.status) {
        case 401 :
          const originalRequest = error.config;
          let isRefreshRequest = false;
          if (originalRequest.url) {
            if (originalRequest.url.toLowerCase().startsWith('login/refreshtoken')) {
              isRefreshRequest = true;
            }
          }
          if (!originalRequest._retry && !isRefreshRequest) {
            const userToken = {
              accessToken: LoginFactory.getAccessToken(),
              refreshToken: LoginFactory.getRefreshToken()
            }
            if (userToken.accessToken && userToken.refreshToken) {
              const newTokens = await refreshToken(userToken.accessToken, userToken.refreshToken);
              if (newTokens.data) {
                LoginFactory.updateTokens(newTokens.data?.accessToken, newTokens.data?.refreshToken, newTokens.data?.isAdmin, newTokens.data?.isAssemblyReady);
                
                originalRequest.headers.authorization = `Bearer ${LoginFactory.getAccessToken()}`;
                originalRequest._retry = true;
    
                return axios(originalRequest);
              } else {
                LoginFactory.doLogout();
                window.location.replace('/login');
              }
            }
          }

          LoginFactory.doLogout();
          window.location.replace('/login');
          break
        /* case 403 :
          history.push('/403'); //Forbidden
          break */
        default :
          break
      }
    }
    // Do something with response error
    return Promise.reject(error);
  }
);

api.interceptors.request.use(
  async (config: AxiosRequestConfig) => {
    store.dispatch(startRequest());

    return config;
  }
)

api.interceptors.response.use(
  function (response) {
    store.dispatch(finishRequest());

    return response;
  }, 
  function (error) {
    store.dispatch(finishRequest());

    return Promise.reject(error);
  }
)

export default api;