import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Copyright from '../../components/Copyright/Copyright';
import { doLogin } from '../../services/login';
import { cpfMask } from '../../util/mask/maskUtil';
import ToastHelper from '../../helpers/ToastHelper/ToastHelper';
import LoginFactory from '../../factory/LoginFactory';
import { useHistory } from 'react-router-dom';
import { validateCpf } from '../../util/validate/validateUtil';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: 'url(images/back-login.jpg)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'left center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(0.5),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function Login({ location } : {location: any}) {
  const [cpf, setCpf] = useState(location?.state?.cpf || '');
  const [cpfError, setCpfError] = useState(false);
  const [pass, setPass] = useState('');
  const [passError, setPassError] = useState(false);
  const classes = useStyles();
  const history = useHistory();

  const handleLoginClick = function() {
    let error = false;
    if (!cpf || !validateCpf(cpf)) {
      ToastHelper.error('CPF Inválido');
      setCpfError(true);
      error = true;
    }
    if (!pass) {
      setPassError(true);
      error = true;
    }

    if (error) {
      return;
    } else {
      setCpfError(false);
      setPassError(false);
    }
    
    doLogin(cpf, pass).then(data => {
      if (data.data.error) {
        ToastHelper.error(data.data?.message || 'Erro ao efetuar login');
      }
      LoginFactory.doLogin(data.data.token, data.data.refreshToken, data.data.name, data.data.avatar, data.data.isAdmin, data.data.isAssemblyReady);
      history.push('/');
    }).catch(data => {
      ToastHelper.error(data.response?.data?.message || 'Erro ao efetuar login');
    });
  }

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Área do sócio
          </Typography>
          <form className={classes.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="cpf"
              label="CPF do sócio"
              name="cpf"
              autoComplete="cpf"
              autoFocus
              value={cpf}
              onChange={(e) => setCpf(cpfMask(e.target.value))}
              error={cpfError}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Senha"
              type="password"
              id="password"
              autoComplete="current-password"
              value={pass}
              onChange={(e) => setPass(e.target.value)}
              error={passError}
            />
            <Button
              type="button"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={() => handleLoginClick()}
            >
              Entrar
            </Button>
            <Grid container>
              <Grid item xs={6}>
                <Link style={{cursor: 'pointer'}} onClick={() => history.push('/forgotpassword', {cpf})} variant="body2">
                  Criar sua senha.
                </Link>
              </Grid>
              <Grid item xs={6} style={{textAlign: 'right'}}>
                <Link style={{cursor: 'pointer'}} onClick={() => history.push('/forgotpassword', {cpf})} variant="body2">
                  Esqueceu sua senha?
                </Link>
              </Grid>
            </Grid>
            <Box mt={5}>
              <Copyright />
            </Box>
          </form>
        </div>
      </Grid>
    </Grid>
  );
}