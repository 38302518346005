import { makeStyles } from "@material-ui/core";

type Props = {
  children: JSX.Element | JSX.Element[],
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    border: 'solid 1px',
    borderRadius: '5px',
    background: '#EEE',
    margin: theme.spacing(2),
    padding: theme.spacing(1)
  },
}))

export default function Container({ children }: Props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {children}
    </div>
  );
}