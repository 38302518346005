import { Button, Grid, IconButton, TextField, Typography } from '@material-ui/core';
import { KeyboardDateTimePicker, KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Spacer from '../../../components/Spacer';
import DateFnsUtils from '@date-io/date-fns';
import ToastHelper from '../../../helpers/ToastHelper/ToastHelper';
import ptLocale from "date-fns/locale/pt-BR";
import { getAssemblyById, postAssembly, putAssembly } from '../../../services/assembly';
import { Add, Delete } from '@material-ui/icons';

export interface IAssembly {
  id?: number
  descricao?: string
  url?: string
  url2?: string
  dataAssembleia?: Date
  dataInicio?: Date
  dataFim?: Date,
  questoes?: IAssemblyQuestion[]
}

export interface IAssemblyQuestion {
  id?: string,
  descricao?: string
}

export default function AssemblyEdit() {
  const history = useHistory();
  const [assembly, setAssembly] = useState<IAssembly>();
  let { id } = useParams<{id?: string}>();

  useEffect(() => {
    if (id === 'new') {
      setAssembly({      
        descricao: '',
        dataAssembleia: new Date(),
        dataInicio: new Date(),
        dataFim: new Date()
      });
    } else {
      getAssemblyById(id || '-1').then((data) => {
        setAssembly(data.data);
      }).catch((ex) => {
        ToastHelper.error('Erro ao obter a assembleia!');
      })
    }
  }, [id]);

  const handleSave = () => {
    //Validade data;
    if (assembly?.id) {
      putAssembly(assembly).then(() => {
        ToastHelper.success('Assembleia salva com sucesso!');
        history.push(`/admin/assembleia`);
      }).catch(() => {
        ToastHelper.error('Erro ao obter a assembleia!');
      })
    } else {
      postAssembly(assembly).then(() => {
        ToastHelper.success('Assembleia salva com sucesso!');
        history.push(`/admin/assembleia`);
      }).catch(() => {
        ToastHelper.error('Erro ao obter a assembleia!');
      })
    }
  }

  const handleAddQuestion = () => {
    setAssembly({
      ...assembly,
      questoes: [...assembly?.questoes || [], {descricao: ''}]
    })
  }

  const updateQuestionDescription = (i: number, value: any) => {
    if (assembly?.questoes) {
      const array = [...assembly.questoes];

      array[i].descricao = value;
      setAssembly({
        ...assembly,
        questoes: array
      })
    }
  }

  const deleteQuestion = (i: number) => {
    if (assembly?.questoes) {
      const array = [...assembly.questoes];

      array.splice(i, 1);
      setAssembly({
        ...assembly,
        questoes: array
      })
    }
  }

  return (
    <>
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="descricao"
        label="Descrição"
        name="descricao"
        autoFocus
        value={assembly?.descricao}
        onChange={(e) => setAssembly({...assembly, descricao: e.target.value})}
      />
      <Spacer/>
      <Grid container direction="row" spacing={2}>
        <Grid item container xs={4}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale}>
            <KeyboardDatePicker
              style={{background: 'white'}}
              fullWidth
              value={assembly?.dataAssembleia}
              onChange={(e: any) => setAssembly({...assembly, dataAssembleia: e})}
              format="dd/MM/yyyy"
              inputVariant="outlined"
              margin="none"
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item container xs={4}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale}>
            <KeyboardDateTimePicker
              style={{background: 'white'}}
              fullWidth
              value={assembly?.dataInicio}
              onChange={(e: any) => setAssembly({...assembly, dataInicio: e})}
              format="dd/MM/yyyy HH:mm:ss"
              inputVariant="outlined"
              margin="none"
              ampm={false}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item container xs={4}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale}>
            <KeyboardDateTimePicker
              style={{background: 'white'}}
              fullWidth
              value={assembly?.dataFim}
              onChange={(e: any) => setAssembly({...assembly, dataFim: e})}
              format="dd/MM/yyyy HH:mm:ss"
              inputVariant="outlined"
              margin="none"
              ampm={false}
            />
          </MuiPickersUtilsProvider>
        </Grid>
      </Grid>

      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="url"
        label="URL"
        name="url"
        autoFocus
        value={assembly?.url}
        onChange={(e) => setAssembly({...assembly, url: e.target.value})}
      />
      <Spacer/>

      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="url2"
        label="URL2"
        name="url2"
        autoFocus
        value={assembly?.url2}
        onChange={(e) => setAssembly({...assembly, url2: e.target.value})}
      />
      <Spacer/>

      <Grid container direction="row" alignItems="center">
        <Grid item container xs={6}>
          <Typography component="h1" variant="h5">
            Perguntas
          </Typography>
        </Grid>
        <Grid item container xs={6} justify={'flex-end'}>
          <IconButton onClick={() => handleAddQuestion()}>
            <Add />
          </IconButton>
        </Grid>
      </Grid>
      {assembly?.questoes?.map((questao, i) => (
        <Grid container direction="row" alignItems="center" key={questao.id}>
          <Grid item container xs={11}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="descricao"
              label="Descrição"
              name="descricao"
              autoFocus
              multiline
              rows={4}
              value={questao.descricao}
              onChange={(e) => updateQuestionDescription(i, e.target.value)}
            />
          </Grid>
          <Grid item container xs={1} justify={'flex-end'}>
            <IconButton onClick={() => deleteQuestion(i)}>
              <Delete />
            </IconButton>
          </Grid>
        </Grid>
      ))}

      <Button
        type="button"
        fullWidth
        variant="contained"
        color="primary"
        onClick={handleSave} >
          Salvar
      </Button>
    </>
  )
}