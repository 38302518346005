import { Check, Error } from "@material-ui/icons";
import { toast } from "react-toastify";

class ToastHelper {
  error = function(message: String) {
    const componentMessage = (
      <div style={{
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
      }}>
        <Error style={{marginRight: 10}} />
        <span>{message.split('\n').map(elem => <div>{elem}</div>)}</span>
      </div>  
    )

    toast.error(componentMessage, {
      closeOnClick: true
    });
  }

  success = function(message: String) {
    const componentMessage = (
      <div style={{
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
      }}>
        <Check style={{marginRight: 10}} />
        <div>
          <span>{message.split('\n').map(elem => <div>{elem}</div>)}</span>
        </div>
      </div>  
    )

    toast.success(componentMessage, {
      closeOnClick: true
    });
  }
}

export default new ToastHelper()