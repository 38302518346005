import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Switch } from 'react-router-dom';
import Login from './pages/login';
import ForgotPassword from './pages/forgotPassword';
import Template from './pages/template';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import RouteWrapper from './components/RouteWrapper';
import { SocketProvider } from './providers/SocketProvider';

ReactDOM.render(
  <React.StrictMode>
    <ToastContainer autoClose={3000} />
    <SocketProvider>
      <BrowserRouter>
        <Switch>
          <RouteWrapper path="/login" exact={true} component={Login} />
          <RouteWrapper path="/forgotpassword" exact={true} component={ForgotPassword} />
          <RouteWrapper path="*" exact={true} component={Template} isPrivate />
        </Switch>
      </BrowserRouter>
    </SocketProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
