import { useEffect, useState, createContext, ReactChild } from "react";

const webSocket  = new WebSocket(process.env.REACT_APP_WEBSOCKET_ENDPOINT || '');
const SOCKET_RECONNECTION_TIMEOUT = 3000;

export const SocketContext = createContext(webSocket);

interface ISocketProvider {
  children: ReactChild;
}

export const SocketProvider = (props: ISocketProvider) => {
  const [ws, setWs] = useState<WebSocket>(webSocket);

  useEffect(() => {
    const onClose = () => {
      setTimeout(() => {
        setWs(new WebSocket(process.env.REACT_APP_WEBSOCKET_ENDPOINT || ''));
      }, SOCKET_RECONNECTION_TIMEOUT);
    };

    ws.addEventListener("close", onClose);

    return () => {
      ws.removeEventListener("close", onClose);
    };
  }, [ws, setWs]);

  return (
    <SocketContext.Provider value={ws}>{props.children}</SocketContext.Provider>
  )
};










/*import { useEffect, useState, createContext, ReactChild } from "react";

const webSocket = new WebSocket("MY_SOCKET_URL");

export const SocketContext = createContext(webSocket);

interface ISocketProvider {
  children: React.ReactChild;
}

export const SocketProvider = (props: ISocketProvider) => {
  const [ws, setWs] = useState < WebSocket > webSocket;

  useEffect(() => {
    const onClose = () => {
      setTimeout(() => {
        setWs(new WebSocket(SOCKET_URL));
      }, SOCKET_RECONNECTION_TIMEOUT);
    };

    ws.addEventListener("close", onClose);

    return () => {
      ws.removeEventListener("close", onClose);
    };
  }, [ws, setWs]);

  return (
    <SocketContext.Provider value={ws}>{props.children}</SocketContext.Provider>
  );
};*/