import { Avatar, makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import LoginFactory from "../../factory/LoginFactory";
import { RootState } from "../../store/modules/root.reducer";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  userContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'center',
    color: 'white',
    paddingRight: '10px'
  },
  logout: {
    border: 'none',
    background: 'none',
    color: '#BBB',
    cursor: 'pointer'
  }
}));

export default function UserData() {
  const classes = useStyles();
  const history = useHistory();
  const user = useSelector((state: RootState) => state.user);

  const handleLogout = function() {
    LoginFactory.doLogout();
    history.push('/');
  }

  return (    
    <div className={classes.root}>
      <div className={classes.userContainer}>
        Olá, {user?.name || ''}
        <div className={classes.logout} onClick={handleLogout}>
          sair
        </div>
      </div>
      <Avatar src={user?.avatar || ''} />
    </div>        
  );
}