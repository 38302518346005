//import React, {ReactDOM} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ReactDOM from 'react-dom';

type props = {
  title?: string,
  message: string | JSX.Element,
  questionMessage?: boolean,
  questionConfirmButton?: string,
  questionDeclineButton?: string
}

export function showModal(props: props) {
  const wrapper = document.body.appendChild(document.createElement('div'));

  const promise = new Promise((resolve, reject) => {
    const removeDialog = function(resolved: boolean) {
      ReactDOM.unmountComponentAtNode(wrapper);
      if (document.body.contains(wrapper)) {
        document.body.removeChild(wrapper)
      }
      
      if (resolved) {
        resolve(true);
      } else {
        resolve(false);
      }
    }

    try {
      ReactDOM.render(
        <div>
          <Dialog
            open={true}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            {props.title && <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>}
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {props.message}
              </DialogContentText>
            </DialogContent>
            {props.questionMessage ? 
              <DialogActions>
                <Button onClick={() => removeDialog(false)} color="secondary">
                  {props.questionDeclineButton || 'Não'}
                </Button>
                <Button onClick={() => removeDialog(true)} color="primary" autoFocus>
                  {props.questionConfirmButton || 'Sim'}
                </Button>
              </DialogActions>
            :
              <DialogActions>
                <Button onClick={() => removeDialog(true)} color="primary" autoFocus>
                  Ok
                </Button>
              </DialogActions>
            } 
          </Dialog>
        </div>,
        wrapper
      );
    } catch (e) {
      console.error(e);
      throw e;
    }
  })

  return promise.then((result) => {
    return result;
  }, (result) => {
    return Promise.reject(result);
  });
}

/* export default function DialogContainer() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Use Google's location service?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Let Google help apps determine location. This means sending anonymous location data to
            Google, even when no apps are running.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Disagree
          </Button>
          <Button onClick={handleClose} color="primary" autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
} */