import { combineReducers } from 'redux'

import loading from './loading/reducer'
import user from './user/reducer'

const combinedReducers = combineReducers({
  loading,
  user
});

export default combinedReducers;

export type RootState = ReturnType<typeof combinedReducers>