import { Grid, IconButton, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, withStyles } from '@material-ui/core';
import { Add, Delete, Edit, Settings } from '@material-ui/icons';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { showModal } from '../../../components/Dialog';
import Spacer from '../../../components/Spacer';
import ToastHelper from '../../../helpers/ToastHelper/ToastHelper';
import { deleteAssembly, getAssemblies } from '../../../services/assembly';

interface IAssembly {
  id: number
  descricao: string
  dataAssembleia: Date
  dataInicio: Date
  dataFim: Date
}

const useStyles = makeStyles({
  table: {
    minWidth: 650
  },
});

const StyledTableCell = withStyles((theme) => ({
  head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);
  
export default function AssemblyList() {
  const classes = useStyles();
  const history = useHistory();
  const [assemblies, setAssemblies] = useState<Array<IAssembly>>([]);

  const refreshList = () => {
    getAssemblies().then((data) => {
      setAssemblies(data.data);
    }).catch(() => {
      ToastHelper.error('Erro ao listar as assembleias!');
    });
  }

  useEffect(() => {
    refreshList();
  }, []);

  const handleEdit = function(assembly: IAssembly) {
    history.push(`/admin/assembleia/${assembly.id}`);
  }

  const handleConfig = function(assembly: IAssembly) {
    history.push(`/admin/assembleia/backoffice/${assembly.id}`);
  }

  const handleAdd = function() {
    history.push(`/admin/assembleia/new`);
  }

  const handleDelete = async function(assembly: IAssembly) {
    if (!await showModal({
      title: 'Exclusão',
      message: 'Deseja realmente excluir a assembleia?',
      questionMessage: true
    })) {
      return;
    }

    const payload = {
      id: assembly.id
    }
    deleteAssembly(payload).then(data => {
      if (data.data.error) {
        showModal({
          title: 'Atenção',
          message: data.data?.message || 'Erro ao apagar assembleia'
        });
        return;
      }
      
      ToastHelper.success('Assembleia apagada com sucesso.');
      refreshList();
    }).catch(data => {
      ToastHelper.error(data.response?.data?.message || 'Erro ao apagar assembleia');
    });
  }

  return (
    <>
      <Grid container direction="row" alignItems="center">
        <Grid item container xs={6}>
          <Typography component="h1" variant="h5">
            Assembleias
          </Typography>
        </Grid>
        <Grid item container xs={6} justify={'flex-end'}>
          <IconButton onClick={() => handleAdd()}>
            <Add />
          </IconButton>
        </Grid>
      </Grid>

      <Spacer />

      <TableContainer component={Paper}>
        <Table className={classes.table} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Descrição</StyledTableCell>
              <StyledTableCell>Data Assembleia</StyledTableCell>
              <StyledTableCell>Data Inicio</StyledTableCell>
              <StyledTableCell>Data Fim</StyledTableCell>
              <StyledTableCell align="right"></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {assemblies.map((assembly) => (
              <TableRow key={assembly.id}>
                <TableCell component="th" scope="row">{assembly.descricao}</TableCell>
                <TableCell>{format(new Date(assembly.dataAssembleia), 'dd/MM/yyyy')}</TableCell>
                <TableCell>{format(new Date(assembly.dataInicio), 'dd/MM/yyyy HH:mm')}</TableCell>
                <TableCell>{format(new Date(assembly.dataFim), 'dd/MM/yyyy HH:mm')}</TableCell>
                <TableCell align="right">
                  <IconButton onClick={() => handleEdit(assembly)}>
                    <Edit />
                  </IconButton>
                  <IconButton onClick={() => handleDelete(assembly)}>
                    <Delete />
                  </IconButton>
                  <IconButton onClick={() => handleConfig(assembly)}>
                    <Settings />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}