import { store } from "../store/store";
import { storeUser, clearUser } from '../store/modules/user/actions'

export interface ILoggedUser {
  accessToken?: string
  refreshToken?: string,
  name?: string,
  avatar?: string,
  isAdmin?: boolean,
  isAssemblyReady?: boolean
}

class LoginFactory {
  private static instance: LoginFactory;
  private user: ILoggedUser = {};

  constructor() {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      const user = JSON.parse(storedUser);

      this.user = {
        accessToken:  user?.accessToken,
        refreshToken: user?.refreshToken
      } 
    }
  }

  static getInstance(): LoginFactory {
    if (!this.instance) {
      this.instance = new LoginFactory();
    }

    return this.instance;
  }

  static isLogged(): boolean {
    return !!(this.getInstance().user?.accessToken);
  }

  static getUserName(): string {
    return this.getInstance().user?.name || '';
  }

  static getAccessToken(): string | undefined {
    return this.getInstance().user?.accessToken;
  }

  static getRefreshToken(): string | undefined {
    return this.getInstance().user?.refreshToken;
  }

  static updateTokens(accessToken: string, refreshToken: string, isAdmin: boolean, isAssemblyReady: boolean) {
    if (!accessToken || !refreshToken) {
      return;
    }

    this.getInstance().user.accessToken = accessToken;
    this.getInstance().user.refreshToken = refreshToken;
    this.getInstance().user.isAdmin = isAdmin;
    this.getInstance().user.isAssemblyReady = isAssemblyReady;

    store.dispatch(storeUser({
      name: this.getInstance().user.name || '',
      avatar: this.getInstance().user.avatar || '',
      logged: true,
      isAdmin,
      isAssemblyReady
    }));

    localStorage.setItem('user', JSON.stringify(this.getInstance().user));
  }

  static doLogin(accessToken: string, refreshToken: string, name: string, avatar: string, isAdmin: boolean, isAssemblyReady: boolean) {
    this.getInstance().user = {
      accessToken,
      refreshToken,
      name,
      avatar,
      isAdmin,
      isAssemblyReady
    }

    store.dispatch(storeUser({
      name,
      avatar,
      logged: true,
      isAdmin,
      isAssemblyReady
    }));
    localStorage.setItem('user', JSON.stringify(this.getInstance().user));
  }

  static doLogout() {
    this.getInstance().user = {}

    store.dispatch(clearUser());
    localStorage.removeItem('user');
  }
}

export default LoginFactory