import React from 'react';
import RouteWrapper from '../../components/RouteWrapper';
import AssemblyBackoffice from './backoffice';
import AssemblyEdit from './edit';
import AssemblyList from './list';

export default function AssemblyRoutes() {
  return [
    <RouteWrapper key={1} path="/admin/assembleia" exact={true} component={AssemblyList} isPrivate />,
    <RouteWrapper key={2} path="/admin/assembleia/:id" exact={true} component={AssemblyEdit} isPrivate />,
    <RouteWrapper key={2} path="/admin/assembleia/backoffice/:id" exact={true} component={AssemblyBackoffice} isPrivate />
  ]
}