import { AxiosResponse } from 'axios';
import api from '../Api';

export async function doLogin(user: string, password: string): Promise<AxiosResponse> {
  return api.post('login/login', {
    user,
    password
  });
}

export async function refreshToken(accessToken: string, refreshToken: string): Promise<AxiosResponse> {
  return api.post('login/refreshToken', {
    accessToken,
    refreshToken
  });
}

export async function createUser(user: string): Promise<AxiosResponse> {
  return api.post('login/createUser', {
    user
  });
}

export async function sendConfirmation(payload: any): Promise<AxiosResponse> {
  return api.post('login/sendConfirmation', payload);
}

export async function confirmPassword(payload: any): Promise<AxiosResponse> {
  return api.post('login/confirmPassword', payload);
}