import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { createUser, sendConfirmation, confirmPassword } from '../../services/login';
import Container from '@material-ui/core/Container';
import Copyright from '../../components/Copyright/Copyright';
import { cpfMask } from '../../util/mask/maskUtil';
import { validateCpf } from '../../util/validate/validateUtil';
import ToastHelper from '../../helpers/ToastHelper/ToastHelper';
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import Spacer from '../../components/Spacer';
import { useHistory } from 'react-router';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

interface IWays {
  type?: string,
  alias?: string,
  contact?: string
}

export default function ForgotPassword({ location } : {location: any}) {
  const [cpf, setCpf] = useState(location?.state?.cpf || '');
  const [cpfError, setCpfError] = useState(false);

  const [code, setCode] = useState('');
  const [codeError, setCodeError] = useState(false);
  const [pass, setPass] = useState('');
  const [passError, setPassError] = useState(false);
  const [confirmPass, setConfirmPass] = useState('');
  const [confirmPassError, setConfirmPassError] = useState(false);

  const [state, setState] = useState(0);
  const [ways, setWays] = useState<Array<IWays>>([]);
  const [selectedWay, setSelectedWay] = useState<string>('');
  const classes = useStyles();

  const history = useHistory();

  const handleForgotClick = function() {
    let error = false;
    if (!cpf || !validateCpf(cpf)) {
      ToastHelper.error('CPF Inválido');
      setCpfError(true);
      error = true;
    }

    if (error) {
      return;
    } else {
      setCpfError(false);
    }
    
    createUser(cpf).then(data => {
      if (data.data.error) {
        ToastHelper.error(data.data?.message || 'Erro ao recuperar senha');
        return;
      }
      setWays(data.data);
      setState(1);
    }).catch(data => {
      ToastHelper.error(data.response?.data?.message || 'Erro ao recuperar senha');
    });
  }

  const handleRadioChange = function (event: any) {
    setSelectedWay(event.target.value);
  }

  const handleSendCodeClick = function () {
    const way = ways.find(elem => elem.contact === selectedWay);
    if (!way) {
      ToastHelper.error('Selecione a forma de envio');
      return;
    }
    
    sendConfirmation({user: cpf, ...way}).then(data => {
      if (data.data.error) {
        ToastHelper.error(data.data?.message || 'Erro ao enviar a confirmação');
        return;
      }
      ToastHelper.success('Código de ativação enviado com sucesso');
      setState(2);
    }).catch(data => {
      ToastHelper.error(data.response?.data?.message || 'Erro ao enviar a confirmação');
    });
  }

  const handleChangePass = function () {
    let error = false;
    let errorList = [];
    if (!cpf || !validateCpf(cpf)) {
      errorList.push('CPF Inválido');
      setCpfError(true);
      error = true;
    }
    if (!code) {
      errorList.push('Código de ativação não informado');
      setCodeError(true);
      error = true;
    } else if (code.replace(/[^0-9]/g, '').length !== 6) {
      errorList.push('Código de ativação precisa ser 6 numeros');
      setCodeError(true);
      error = true;
    } else {
      setCodeError(false);
    }
    if (!pass) {
      errorList.push('Nova senha não informada');
      setPassError(true);
      error = true;
    } else if (pass.length < 6) {
      errorList.push('A senha precisa de no minimo 6 caracteres');
      setPassError(true);
      error = true;
    }
    if (!confirmPass) {
      errorList.push('Confirmação de senha não informada');
      setConfirmPassError(true);
      error = true;
    }
    if (pass !== confirmPass) {
      errorList.push('As senhas não conferem');
      setPassError(true);
      setConfirmPassError(true);
    }

    if (error) {
      ToastHelper.error(errorList.join('\n'));
      return;
    } else {
      setCodeError(false);
      setPassError(false);
      setConfirmPassError(false);
    }
    
    const payload = {
      user: cpf,
      confirmationCode: code,
      password: pass,
      confirmPassword: confirmPass
    }
    confirmPassword(payload).then(data => {
      if (data.data.error) {
        ToastHelper.error(data.data?.message || 'Erro ao alterar senha');
        return;
      }
      
      ToastHelper.success('Senha alterada com sucesso.');
      history.push('/login', {cpf});
    }).catch(data => {
      ToastHelper.error(data.response?.data?.message || 'Erro ao alterar senha');
    });
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Esqueci minha senha
        </Typography>
        {(state === 0) && 
        <>
          <form className={classes.form} noValidate>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="cpf"
                  label="CPF do sócio"
                  name="cpf"
                  autoComplete="cpf"
                  autoFocus
                  value={cpf}
                  onChange={(e) => setCpf(cpfMask(e.target.value))}
                  error={cpfError}
                />
              </Grid>
            </Grid>
            <Button
              type="button"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={() => handleForgotClick()}
            >
              Continuar
            </Button>
            <Grid container justify="flex-end">
              <Grid item>
                <Link style={{cursor: 'pointer'}} onClick={() => history.push('/login', {cpf})} variant="body2">
                  Já tem uma senha? Efetuar Login
                </Link>
              </Grid>
            </Grid>
          </form>
        </>}
        {(state === 1) && 
        <>
          {(Array.isArray(ways) && ways.length) ?
            <form className={classes.form} noValidate>
              <Spacer />
              <Typography>
                Será enviado um código de ativação, selecione a forma com que você deseja receber este código
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                <RadioGroup aria-label="way" name="way" value={selectedWay} onChange={handleRadioChange}>
                  {
                    ways.map(way => {
                      return (
                        <FormControlLabel key={way.contact} value={way.contact} control={<Radio />} label={`${way.alias}: ${way.contact}`} />
                      )
                    })
                  }
                </RadioGroup>
                </Grid>
              </Grid>
              <Button
                type="button"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={() => handleSendCodeClick()}
              >
                Enviar
              </Button>
            </form>
          : <>
            <Spacer />
            <Typography>
              Não foi encontrado nenhum e-mail ou telefone no seu cadastro, entre em contato com a secretaria do clube para prosseguir com o acesso a área do sócio. Atualize seu CPF, E-Mail e celular.
            </Typography>
          </>}
        </>}
        {(state === 2) && <>
          <form className={classes.form} noValidate>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="code"
                  label="Código de ativação"
                  name="code"
                  autoFocus
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                  error={codeError}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Nova senha"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  value={pass}
                  onChange={(e) => setPass(e.target.value)}
                  error={passError}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="confirmpassword"
                  label="Confirme a nova senha"
                  type="password"
                  id="confirmpassword"
                  autoComplete="current-password"
                  value={confirmPass}
                  onChange={(e) => setConfirmPass(e.target.value)}
                  error={confirmPassError}
                />
              </Grid>
            </Grid>
            <Button
              type="button"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={() => handleChangePass()}
            >
              Criar senha
            </Button>
          </form>
          <Grid container justify="flex-end">
            <Grid item>
              <Link onClick={() => setState(1)} component="button">
                Reenviar código de confirmação
              </Link>
            </Grid>
          </Grid>
        </>}
      </div>
      <Box mt={5}>
        <Copyright />
      </Box>
    </Container>
  );
}