import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { getBillet, getPDF } from '../../services/billet';
import { PictureAsPdf } from '@material-ui/icons';
import { formatCurrency, formatDate } from '../../util/format/formatUtils';
import { IconButton, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, withStyles } from '@material-ui/core';
import Spacer from '../../components/Spacer';
import ToastHelper from '../../helpers/ToastHelper/ToastHelper';
import toastHelper from '../../helpers/ToastHelper/ToastHelper';

interface IBilling {
    id: number
    emissao: Date
    vencto: Date
    valor: number
    valorPago: number
    imprimeBoleto: boolean
    assocMesano: string
    status: string
}

const useStyles = makeStyles({
  table: {
    minWidth: 650
  },
});

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const formatMesAno = function (mesAno: string) {
  if ((!mesAno) || (mesAno.length !== 6)) {
    return mesAno;
  }
  return `${mesAno.substr(0, 2)}/${mesAno.substr(2)}`
}

const rowBackColor = function(billet: IBilling) {
  if (billet.status === 'Paga') {
    return '#c3e6cb';
  } else if (billet.status === 'Vencida') {
    return '#f5c6cb';
  } else if (billet.status === 'A Vencer') {
    return '#b8daff';
  }
}

export default function Billing() {
  const [billets, setBillets] = useState<Array<IBilling>>([]);
  const classes = useStyles();

  useEffect(() => {
    getBillet().then((data) => {
      setBillets(data.data);
    }).catch(() => {
      toastHelper.error('Erro ao listar os boletos!');
    })
  }, []);

  const printPdf = function(billet: IBilling) {
    getPDF(billet.id).then((data) => {
      const pdf = data.data;
      if (pdf) {
        const file = new Blob([pdf], {
          type: "application/pdf;charset=utf-8;"
        });
        const fileURL = URL.createObjectURL(file);
    
        /*var win = window.open(fileURL, "_blank");
        if (win) {
          win.focus();
        }*/

        /*console.log(fileURL)

        const k = document.createElement('a');
        k.setAttribute('href', fileURL);
        k.setAttribute('target', '_blank');
        k.click();*/

        window.location.href = fileURL;
      }
    }).catch((ex) => {
      ToastHelper.error('Erro ao carregar PDF');
    })
  }

  return (
    <>
      <Typography component="h1" variant="h5">
        Ficha financeira
      </Typography>
      <Spacer />

      <TableContainer component={Paper}>
        <Table className={classes.table} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Mes/Ano</StyledTableCell>
              <StyledTableCell>Emissão</StyledTableCell>
              <StyledTableCell>Vencimento</StyledTableCell>
              <StyledTableCell align="right">Valor</StyledTableCell>
              <StyledTableCell align="right">Valor pago</StyledTableCell>
              <StyledTableCell align="center">Status</StyledTableCell>
              <StyledTableCell align="right"></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {billets.map((billet) => (
              <TableRow key={billet.id} style={{backgroundColor: rowBackColor(billet)}}>
                <TableCell component="th" scope="row">
                {formatMesAno(billet.assocMesano)}
                </TableCell>
                <TableCell>{formatDate(billet.emissao)}</TableCell>
                <TableCell>{formatDate(billet.vencto)}</TableCell>
                <TableCell align="right">{formatCurrency(billet.valor)}</TableCell>
                <TableCell align="right">{formatCurrency(billet.valorPago)}</TableCell>
                <TableCell align="center">{billet.status}</TableCell>
                <TableCell align="right">
                  {billet.imprimeBoleto ? <>
                    <IconButton onClick={() => printPdf(billet)}>
                      <PictureAsPdf />
                    </IconButton>
                  </> : <></>}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}