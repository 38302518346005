import { Card, CardHeader, Grid, IconButton, Typography } from '@material-ui/core';
import { Block, HowToVote, Refresh, ThumbDown, ThumbUp } from '@material-ui/icons';
import { format } from 'date-fns';
import React, { useCallback, useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { showModal } from '../../components/Dialog';
import ToastHelper from '../../helpers/ToastHelper/ToastHelper';
import { useSocket } from '../../providers/useSocket';
import { getAssemblies, vote } from '../../services/assemblyVote';

export interface IAssembly {
  id?: number
  descricao?: string
  url?: string
  url2?: string
  dataAssembleia?: Date
  dataInicio?: Date
  dataFim?: Date,
  visivel?: boolean,
  questoes?: IAssemblyQuestion[]
}

export interface IAssemblyQuestion {
  id?: string,
  descricao?: string,
  visivel?: boolean,
  resposta?: boolean
}

export default function AssemblyVote() {
  const [assemblies, setAssemblies] = useState<Array<IAssembly>>();
  const socket = useSocket();
  
  const onMessage = useCallback((message) => {
    const data = JSON.parse(message?.data);
    if (data?.method === 'refresh') {
      refresh();
    }
  }, []);
  
  useEffect(() => {
    socket.addEventListener("message", onMessage);

    return () => {
      socket.removeEventListener("message", onMessage);
    };
  }, [socket, onMessage]);

  const refresh = function() {
    getAssemblies().then((data) => {
      setAssemblies(data.data);
    }).catch((ex) => {
      ToastHelper.error('Erro ao obter a assembleia!');
    })
  }

  useEffect(() => {
    refresh();
  }, []);

  const handleVote = async function(question: IAssemblyQuestion) {
    const answer = await showModal({
      message: <ReactMarkdown children={question.descricao || ''} />,
      questionMessage: true,
      questionConfirmButton: 'Concordo',
      questionDeclineButton: 'Não Concordo'
    });

    vote(question.id || '-1', !!answer).then((data) => {
      setAssemblies(data.data);
    }).catch((ex) => {
      ToastHelper.error('Erro ao obter a assembleia!');
    })
  }

  const getIcon = function(question: IAssemblyQuestion)
  {
    if (question.resposta === true) {
      return (
        <ThumbUp style={{ color: 'green' }}>
        </ThumbUp>
      )
    } else if (question.resposta === false) {
      return (
        <ThumbDown style={{ color: 'red' }}>
        </ThumbDown>
      )
    } else {
      return (
        <Block>
        </Block>
      )
    }
  }

  return (
    <>
      {assemblies && assemblies.length ? 
      <>
        {assemblies.map(assembly =>
          <div key={assembly.id}>
            <Grid container direction="row" alignItems="center">
              <Grid item container xs={11}>
                <Typography component="h1" variant="h5">
                {assembly.descricao}
                </Typography>
              </Grid>
              <Grid item container xs={1} justify={'flex-end'}>
                <IconButton onClick={() => refresh()}>
                  <Refresh />
                </IconButton>
              </Grid>
            </Grid>


            <Grid container direction="row" alignItems="center">
              <Grid item container xs={12} sm={12}>
                <b>Data Assembleia:</b> {format(new Date(assembly.dataAssembleia || ''), 'dd/MM/yyyy')}<br/>
              </Grid>
              <Grid item container xs={12} sm={12}>
                <b>Data Inicio:</b> {format(new Date(assembly.dataInicio || ''), 'dd/MM/yyyy HH:mm')}<br/>
              </Grid>
              <Grid item container xs={12} sm={12}>
                <b>Data Fim:</b> {format(new Date(assembly.dataFim || ''), 'dd/MM/yyyy HH:mm')}<br/>
              </Grid>
              <Grid item container xs={12} sm={12}>
                {assembly.url && <>
                  <b>Participe através do link:</b>&nbsp;<a href={assembly.url} target="_blank" rel="noreferrer">{assembly.url}</a> {assembly.url2 && <>&nbsp;ou&nbsp;<a href={assembly.url2} target="_blank" rel="noreferrer">{assembly.url2}</a></>}
                </>}
              </Grid>
            </Grid>

            {assembly.questoes && assembly.questoes?.length ? 
              <>
                <h3>Proposta de alteração:</h3>
                {assembly.questoes.map((question) => 
                  <Card variant="outlined" key={question.id} style={{margin: '5px'}}>
                     <CardHeader
                      avatar={getIcon(question)}
                      subheader={<ReactMarkdown children={question.descricao || ''} />}
                      action={
                        <IconButton aria-label="settings" onClick={() => handleVote(question)}>
                          <HowToVote />
                        </IconButton>
                      }
                    />
                  </Card>
                )}
              </>
              :
              <>
                <h3>Proposta de alteração:</h3>
                <Typography component="h6" variant="h6">
                  Nenhuma proposta de alteração em votação.
                </Typography>
              </>
            }
          </div>
        )}
      </>
      :
      <>
        <Grid container direction="row" alignItems="center">
          <Grid item container xs={6}>
            <Typography component="h1" variant="h5">
              Nenhuma assembleia em andamento.
            </Typography>
          </Grid>
          <Grid item container xs={6} justify={'flex-end'}>
            <IconButton onClick={() => refresh()}>
              <Refresh />
            </IconButton>
          </Grid>
        </Grid>
      </>
      }
    </>
  );
}