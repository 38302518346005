import api from '../Api';

export async function getBillet() {
  return api.get('billet/list');
}

export async function getPDF(numSist: number) {
  return api.get(`billet/pdf/${numSist}`, {
    timeout: 10000,
    responseType: 'arraybuffer'
  });
}