import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import LoginFactory from '../../factory/LoginFactory';

export default function RouteWrapper({
  component: Component,
  isPrivate = false,
  ...rest
}: any) {
  const signed = LoginFactory.isLogged();

  if (!signed && isPrivate) {
    return <Redirect to='/login' />
  }

  if (signed && !isPrivate) {
    return <Redirect to='/' />
  }

  return (
    <Route 
      {...rest}
      component={Component}
    />  
  )
}