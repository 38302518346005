import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBarLayout from '../../components/AppBarLayout';
import DrawerLayout from '../../components/DrawerLayout';
import MainContent from '../../components/MainContent';
import { Provider } from 'react-redux';
import { store } from '../../store/store';
import Loading from '../../components/Loading';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
}));

export default function Template() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <Provider store={store}>
        <CssBaseline />
        <Loading />
        <AppBarLayout open={open} handleDrawerOpen={handleDrawerOpen}/>
        <DrawerLayout open={open} handleDrawerClose={handleDrawerClose} />
        
        <MainContent />
      </Provider>
    </div>
  );
}