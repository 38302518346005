import { capitalizeFirstLetter } from "../../../util/format/formatUtils";

export interface IAction {
  type: string,
  user: IUserState
}

export interface IUserState {
  name: string,
  avatar: string,
  logged: boolean,
  isAdmin: boolean,
  isAssemblyReady: boolean
}

const getInitialState = function () : IUserState {
  let name = '';
  let avatar = '';
  let logged = false;
  let isAdmin = false;
  let isAssemblyReady = false;

  const storedUser = localStorage.getItem('user');
  if (storedUser) {
    const user = JSON.parse(storedUser);
    name = capitalizeFirstLetter((user.name || '').replace(/ .*/, ''));
    avatar = user.avatar;
    logged = !!(user.token);
    isAdmin = user.isAdmin;
    isAssemblyReady = user.isAssemblyReady;
  }

  return {
    name,
    avatar,
    logged,
    isAdmin,
    isAssemblyReady
  }
}

const INITIAL_STATE: IUserState = getInitialState();

export default function loading(state: IUserState = INITIAL_STATE, action: IAction): IUserState {
  switch (action.type) {
    case '@user/CLEAR_USER':
      return action.user
    case '@user/STORE_USER':
      return {
        logged: action.user.logged,
        name: capitalizeFirstLetter(action.user.name.replace(/ .*/, '')),
        avatar: action.user.avatar,
        isAdmin: action.user.isAdmin,
        isAssemblyReady: action.user.isAssemblyReady
      }
    default: 
      return state;
  }
}