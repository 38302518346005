import { Card, CardHeader, Grid, IconButton, Switch, Typography } from "@material-ui/core"
import { Add, Refresh, Visibility } from "@material-ui/icons"
import { format } from "date-fns"
import { useEffect, useState } from "react"
import ReactMarkdown from "react-markdown"
import { PieChart } from "react-minimal-pie-chart"
import { useParams } from "react-router-dom"
import { showModal } from "../../../components/Dialog"
import ToastHelper from "../../../helpers/ToastHelper/ToastHelper"
import { getAssemblyConfigById, putAssemblyConfigAddQuestion, putAssemblyVisibilityConfigById, putAssemblyVisibilityQuestionConfigById } from "../../../services/assembly"

export interface IAssembly {
  id?: number
  descricao?: string
  dataAssembleia?: Date
  dataInicio?: Date
  dataFim?: Date,
  visivel?: boolean,
  questoes?: IAssemblyQuestion[]
}

export interface IAssemblyQuestion {
  id?: string,
  descricao?: string,
  visivel?: boolean,
  respostas?: number,
  respostasSim?: number,
  respostasNao?: number
}

export default function AssemblyBackoffice() {
  const [assembly, setAssembly] = useState<IAssembly>();
  let { id } = useParams<{id: string}>();

  const refresh = () => {
    getAssemblyConfigById(id).then((data) => {
      setAssembly(data.data);
    }).catch((ex) => {
      ToastHelper.error('Erro ao obter a assembleia!');
    })
  }

  useEffect(() => {
    refresh();
  }, [id]);
  
  const setAssemblyVisible = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    if (!await showModal({
      title: 'Visibilidade',
      message: `Deseja realmente ${checked ? 'ativar' : 'inativar'} a assembleia?`,
      questionMessage: true
    })) {
      return;
    }

    putAssemblyVisibilityConfigById(id, checked).then((data) => {
      setAssembly(data.data);
    }).catch((ex) => {
      ToastHelper.error('Erro ao atualizar a assembleia!');
    })
  };

  const setQuestionVisible = async (event: React.ChangeEvent<HTMLInputElement>, question :IAssemblyQuestion) => {
    const checked = event.target.checked;
    if (!await showModal({
      title: 'Visibilidade',
      message: <>{`Deseja realmente ${checked ? 'ativar' : 'inativar'} a pergunta:`}<br/><ReactMarkdown children={question.descricao || ''} /></>,
      questionMessage: true
    })) {
      return;
    }

    putAssemblyVisibilityQuestionConfigById(id, question.id || '-1', checked).then((data) => {
      setAssembly(data.data);
    }).catch((ex) => {
      ToastHelper.error('Erro ao atualizar a pergunta!');
    })
  };

  const handleAddQuestion = async (assemblyId: number | undefined) => {
    if (!assemblyId) {
      return;
    }

    const description = prompt('Digite a nova pergunta');
    if (!description || description.trim() === '') {
      return;
    }

    putAssemblyConfigAddQuestion(id, description).then((data) => {
      setAssembly(data.data);
    }).catch((ex) => {
      ToastHelper.error('Erro ao adicionar a pergunta!');
    })
  }

  return (
    <>
      {assembly && <>
        <Grid container direction="row" alignItems="center">
          <Grid item container xs={6}>
            <Typography component="h1" variant="h5">
            {assembly.descricao}
            </Typography>
          </Grid>
          <Grid item container xs={6} justify={'flex-end'}>
            <IconButton onClick={() => refresh()}>
              <Refresh />
            </IconButton>
          </Grid>
        </Grid>

        <b>Data Assembleia:</b> {format(new Date(assembly.dataAssembleia || ''), 'dd/MM/yyyy')}<br/>
        <b>Data Inicio:</b> {format(new Date(assembly.dataInicio || ''), 'dd/MM/yyyy HH:mm')}<br/>
        <b>Data Fim:</b> {format(new Date(assembly.dataFim || ''), 'dd/MM/yyyy HH:mm')}<br/>

        <b>Visivel:</b><Switch checked={!!assembly.visivel} onChange={setAssemblyVisible} inputProps={{ 'aria-label': 'controlled' }}></Switch>

        <Grid container direction="row" alignItems="center">
          <Grid item container xs={6}>
            <Typography component="h6" variant="h6">
              Perguntas:
            </Typography>
          </Grid>
          <Grid item container xs={6} justify={'flex-end'}>
            <IconButton onClick={() => handleAddQuestion(assembly.id)}>
              <Add />
            </IconButton>
          </Grid>
        </Grid>

        {assembly?.questoes?.map((question) => (
          <Card variant="outlined" key={question.id}>
            <CardHeader
              avatar={
                <>
                  <PieChart
                    style={{ height: '60px' }}
                    lineWidth={25} rounded
                    label={(props) => { return props.dataEntry.value;}}
                    data={[
                      { title: 'Sim', value: question.respostasSim || 0, color: '#0F0' },
                      { title: 'Nao', value: question.respostasNao || 0, color: '#F00' }
                    ]}
                  />
                  <Typography>{question.respostas} voto(s)</Typography>
                </>
              }
              subheader={<ReactMarkdown children={question.descricao || ''} />}
              action={
                <div style={{textAlign: 'center'}}>
                  <Visibility style={{color: '#aaa'}} /><br/>
                  <Switch checked={!!question.visivel} onChange={(event) => setQuestionVisible(event, question)} inputProps={{ 'aria-label': 'controlled' }}></Switch>
                </div>
              }
            />
          </Card>
        ))}
      </>}
    </>
  )
}