import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { getSchedules, getPersons, getModalities, getHours, putSchedule, deleteSchedule } from '../../services/schedule';
import { formatDate, formatQueryDate, jsonDateWithoutTZ } from '../../util/format/formatUtils';
import { Button, IconButton, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, withStyles } from '@material-ui/core';
import Select from 'react-select'
import Spacer from '../../components/Spacer';
import Container from '../../components/Container';
import toastHelper from '../../helpers/ToastHelper/ToastHelper';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ToastHelper from '../../helpers/ToastHelper/ToastHelper';
import { Delete } from '@material-ui/icons';
import ptLocale from "date-fns/locale/pt-BR";
import { showModal } from '../../components/Dialog';
import ReCAPTCHA from "react-google-recaptcha";

interface ISchedule {
    id: number
    idSocio: number
    nome: string
    idModalidade: number
    modalidade: number
    idHorario: number
    horario: string
    data: Date
}

const useStyles = makeStyles({
  table: {
    minWidth: 650
  },
});

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

export default function Schedule() {
  const [schedules, setSchedules] = useState<Array<ISchedule>>([]);
  const [persons, setPersons] = useState([]);
  const [person, setPerson] = useState<any>();
  const [modalities, setModalities] = useState([]);
  const [modalitie, setModalitie] = useState<any>();
  const [hours, setHours] = useState([]);
  const [hour, setHour] = useState<any>();
  const [date, setDate] = useState<Date | null>(new Date());
  const [captcha, setCaptcha] = useState<String | null>(null);
  const classes = useStyles();
  var captchaSiteKey: string = process.env.REACT_APP_RECAPTCHA_SITE_KEY || '';

  const captchaRef = React.createRef<ReCAPTCHA>();

  const refreshList = function() {
    getSchedules().then((data) => {
      setSchedules(data.data.map((elem: ISchedule) => {
        return {
          ...elem,
          data: jsonDateWithoutTZ(elem.data)
        }
      }));
    }).catch(() => {
      toastHelper.error('Erro ao listar atividades!');
    })
  }

  useEffect(() => {
    refreshList();

    getPersons().then((data) => {
      const persons = data.data.map((elem: any) => {
        return {
          value: elem.ID,
          label: elem.Nome
        }
      });
      setPersons(persons);
      setPerson(persons.length ? persons[0] : undefined);
    }).catch(() => {
      toastHelper.error('Erro ao listar sócios!');
    })
  }, []);

  useEffect(() => {
    if ((!date)) {
      setModalities([]);
      return;
    }
    getModalities(date).then((data) => {
      const modalities = data.data.map((elem: any) => {
        return {
          value: elem.ID,
          label: elem.Descricao
        }
      });
      setModalities(modalities);
      setModalitie(modalities.length ? modalities[0] : undefined);
    }).catch(() => {
      toastHelper.error('Erro ao listar as modalidades!');
    })
  }, [date]);

  useEffect(() => {
    if ((!date || !modalitie)) {
      setHours([]);
      return;
    }
    getHours(date, modalitie?.value as Number).then((data) => {
      const hours = data.data.map((elem: any) => {
        return {
          value: elem.ID,
          label: elem.Descricao
        }
      });
      setHours(hours);
      setHour(hours.length ? hours[0] : undefined);
    }).catch((ex) => {
      toastHelper.error('Erro ao listar os horários!');
    })
  }, [modalitie, date]);

  const handleAddSchedule = function() {
    let error = false;
    let errorList = [];
    if (!person) {
      errorList.push('Informe o sócio');
      error = true;
    }
    if (!date) {
      errorList.push('Informe a data');
      error = true;
    }
    if (!modalitie) {
      errorList.push('Informe a modalidade');
      error = true;
    }
    if (!hour) {
      errorList.push('Informe a hora');
      error = true;
    }
    if (!captcha) {
      errorList.push('Você é um robô?');
      error = true;
    }

    if (error) {
      ToastHelper.error(errorList.join('\n'));
      return;
    }
    if (!date) {
      return;
    }

    const payload = {
      person: person.value,
      modalitie: modalitie.value,
      hour: hour.value,
      date: formatQueryDate(date),
      captcha
    }
    if (captchaRef.current) captchaRef.current.reset();
    setCaptcha(null);
    putSchedule(payload).then(data => {
      if (data.data.error) {
        showModal({
          title: 'Atenção',
          message: data.data?.message || 'Erro ao criar agendamento'
        });
        //ToastHelper.error(data.data?.message || 'Erro ao criar agendamento');
        return;
      }
      
      ToastHelper.success('Agendamento efetuado com sucesso.');
      refreshList();
    }).catch(data => {
      ToastHelper.error(data.response?.data?.message || 'Erro ao criar agendamento');
    });
  }

  const handleDelete = async function(schedule: ISchedule) {
    if (!await showModal({
      title: 'Exclusão',
      message: 'Deseja realmente excluir o agendamento?',
      questionMessage: true
    })) {
      return;
    }

    const payload = {
      person: schedule.idSocio,
      modalitie: schedule.idModalidade,
      hour: schedule.idHorario,
      date: formatQueryDate(schedule.data)
    }
    deleteSchedule(payload).then(data => {
      if (data.data.error) {
        showModal({
          title: 'Atenção',
          message: data.data?.message || 'Erro ao apagar agendamento'
        });
        return;
      }
      
      ToastHelper.success('Agendamento apagado com sucesso.');
      refreshList();
    }).catch(data => {
      ToastHelper.error(data.response?.data?.message || 'Erro ao apagar agendamento');
    });
  }

  return (
    <>
      <Typography component="h1" variant="h5">
        Atividades
      </Typography>
      <Spacer />

      <Container>
        <Select options={persons} value={person} onChange={(e: any) => setPerson(e)} />
        <Spacer/>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale}>
          <KeyboardDatePicker
            style={{background: 'white'}}
            fullWidth
            disablePast            
            value={date}
            onChange={setDate}
            format="dd/MM/yyyy"
            inputVariant="outlined"
            margin="none"
          />
        </MuiPickersUtilsProvider>
        <Spacer/>
        <Select options={modalities} value={modalitie} onChange={(e: any) => setModalitie(e)} />
        <Spacer/>
        <Select options={hours} value={hour} onChange={(e: any) => setHour(e)} />
        <Spacer/>
        <ReCAPTCHA sitekey={captchaSiteKey} onChange={(value) => setCaptcha(value)} ref={captchaRef} />
        <Spacer/>
        <Button
          type="button"
          fullWidth
          variant="contained"
          color="primary"
          onClick={handleAddSchedule} >
            Incluir
        </Button>
      </Container>

      <TableContainer component={Paper}>
        <Table className={classes.table} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Sócio</StyledTableCell>
              <StyledTableCell>Modalidade</StyledTableCell>
              <StyledTableCell>Data</StyledTableCell>
              <StyledTableCell>Horário</StyledTableCell>
              <StyledTableCell align="right"></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {schedules.map((schedule) => (
              <TableRow key={schedule.id}>
                <TableCell component="th" scope="row">
                  {schedule.nome}
                </TableCell>
                <TableCell>{schedule.modalidade}</TableCell>
                <TableCell>{formatDate(schedule.data)}</TableCell>
                <TableCell>{schedule.horario}</TableCell>
                <TableCell align="right">
                  <IconButton onClick={() => handleDelete(schedule)}>
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}