import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Billing from '../../pages/billing';
import Schedule from '../../pages/schedule';
import Dashboard from '../../pages/dashboard';
import Page404 from '../../pages/defaults/404';
import RouteWrapper from '../RouteWrapper';
import { Switch } from 'react-router-dom';
import MyData from '../../pages/myData';
import AssemblyRoutes from '../../pages/assembly';
import AssemblyVote from '../../pages/assemblyVote';

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  appBarSpacer: theme.mixins.toolbar,
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
}));

export default function MainContent() {
  const classes = useStyles();

  return (
    <main className={classes.content}>
      <div className={classes.appBarSpacer} />
      <Container maxWidth={false} className={classes.container}>
        <Switch>
          <RouteWrapper path="/atividades" exact={true} component={Schedule} isPrivate />
          <RouteWrapper path="/boletos" exact={true} component={Billing} isPrivate />
          {AssemblyRoutes()}
          <RouteWrapper path="/assembleia" exact={true} component={AssemblyVote} isPrivate />
          <RouteWrapper path="/meusdados" exact={true} component={MyData} isPrivate />
          <RouteWrapper path="/" exact={true} component={Dashboard} isPrivate />
          <RouteWrapper path="*" component={Page404} />
        </Switch>
      </Container>
    </main>
  )
}