import api from '../Api';
import { formatQueryDate } from '../../util/format/formatUtils'

export async function getSchedules() {
  return api.get('schedule/list');
}

export async function getPersons() {
  return api.get('schedule/persons');
}

export async function getModalities(date: Date) {
  return api.get('schedule/modalities', {
    params: {
      date: formatQueryDate(date)
    }
  });
}

export async function getHours(date: Date, modalitie: Number) {
  return api.get('schedule/hours', {
    params: {
      date: formatQueryDate(date),
      modalitie
    }
  });
}

export async function putSchedule(data: any) {
  return api.put('schedule', data);
}

export async function deleteSchedule(data: any) {
  return api.delete('schedule', {
    data
  });
}