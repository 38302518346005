import React, { MouseEventHandler } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Link } from 'react-router-dom';
import { AssignmentInd, AttachMoney, Dashboard, FitnessCenter, HowToVote, Settings } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/modules/root.reducer';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
}));

type Props = {
  handleDrawerClose: MouseEventHandler<HTMLElement>
  open: boolean
}

export default function DrawerLayout(props:Props) {
  const classes = useStyles();
  const user = useSelector((state: RootState) => state.user);

  return (
    <Drawer
      variant="permanent"
      classes={{
        paper: clsx(classes.drawerPaper, !props.open && classes.drawerPaperClose),
      }}
      open={props.open}
    >
      <div className={classes.toolbarIcon}>
        <IconButton onClick={props.handleDrawerClose}>
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <Divider />
      <ListItem button component={Link} to="/">
        <ListItemIcon>
          <Dashboard />
        </ListItemIcon>
        <ListItemText primary="Inicio" />
      </ListItem>
      <ListItem button component={Link} to="/boletos">
        <ListItemIcon>
          <AttachMoney />
        </ListItemIcon>
        <ListItemText primary="Ficha financeira" />
      </ListItem>
      <ListItem button component={Link} to="/atividades">
        <ListItemIcon>
          <FitnessCenter />
        </ListItemIcon>
        <ListItemText primary="Atividades" />
      </ListItem>
      <ListItem button component={Link} to="/meusdados">
        <ListItemIcon>
          <AssignmentInd />
        </ListItemIcon>
        <ListItemText primary="Meus Dados" />
      </ListItem>
      { user.isAdmin &&
        <ListItem button component={Link} to="/admin/assembleia">
          <ListItemIcon>
            <Settings />
          </ListItemIcon>
          <ListItemText primary="Assembleias" />
        </ListItem>
      }
      { user.isAssemblyReady &&
        <ListItem button component={Link} to="/assembleia">
          <ListItemIcon>
            <HowToVote />
          </ListItemIcon>
          <ListItemText primary="Assembleia" />
        </ListItem>
      }
    </Drawer>
  )
}