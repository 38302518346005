export function formatDate(paramDate: Date) : string | undefined {
  let date: Date;
  if (paramDate instanceof Date) {
    date = paramDate;
  } else {
    date = new Date(paramDate);
  }
  date.setTime(date.getTime() + date.getTimezoneOffset()*60*1000 ); //remove timezone
  
  if (date instanceof Date) {
    var day = date.getDate().toString().padStart(2, '0');
    var month = (date.getMonth() + 1).toString().padStart(2, '0');
    var year = date.getFullYear();
        
    return `${day}/${month}/${year}`;
  }

  return '';
}

export function formatDateHour(paramDate: Date) : string | undefined {
  let date: Date;
  if (paramDate instanceof Date) {
    date = paramDate;
  } else {
    date = new Date(paramDate);
  }
  date.setTime(date.getTime() + date.getTimezoneOffset()*60*1000 ); //remove timezone
  
  if (date instanceof Date) {
    var day = date.getDate().toString().padStart(2, '0');
    var month = (date.getMonth() + 1).toString().padStart(2, '0');
    var year = date.getFullYear();
    var hour = date.getHours();
    var minute = date.getMinutes();
    var second = date.getSeconds();
        
    return `${day}/${month}/${year} ${hour}:${minute}:${second}`;
  }

  return '';
}

export function formatQueryDate(paramDate: Date) : string | undefined {
  let date: Date;
  if (paramDate instanceof Date) {
    date = paramDate;
  } else {
    date = new Date(paramDate);
  }
  if (date instanceof Date) {
    var day = date.getDate().toString().padStart(2, '0');
    var month = (date.getMonth() + 1).toString().padStart(2, '0');
    var year = date.getFullYear();

    return `${year}-${month}-${day}`;
  }

  return '';
}

export function jsonDateWithoutTZ(date: Date) : Date {
  let newDate = new Date(date);
  newDate.setHours(newDate.getHours() + 3);

  return new Date(newDate);
}

export function formatCurrency(value: number) : string | undefined {
  return `R$ ${value.toLocaleString('pt-br', {maximumFractionDigits: 2, minimumFractionDigits: 2})}`;
}

export function capitalizeFirstLetter(value: string) {
  const string = value.toLowerCase();
  return string.charAt(0).toUpperCase() + string.slice(1);
}
