import { IAssembly } from '../../pages/assembly/edit';
import api from '../Api';

export async function getAssemblies() {
  return api.get('assembly');
}

export async function getAssemblyById(id: string) {
  return api.get<IAssembly>(`assembly/${id}`);
}

export async function postAssembly(data: any) {
  return api.post(`assembly`, data);
}

export async function putAssembly(data: any) {
  return api.put(`assembly/${data.id}`, data);
}

export async function deleteAssembly(data: any) {
  return api.delete(`assembly/${data.id}`, {data});
}

export async function getAssemblyConfigById(id: string) {
  return api.get<IAssembly>(`assembly/config/${id}`);
}

export async function putAssemblyVisibilityConfigById(id: string, visible: boolean) {
  return api.put<IAssembly>(`assembly/config/visible/${id}`, {visivel: visible});
}

export async function putAssemblyVisibilityQuestionConfigById(id: string, questionId: string, visible: boolean) {
  return api.put<IAssembly>(`assembly/config/visible/question/${id}/${questionId}`, {visivel: visible});
}

export async function putAssemblyConfigAddQuestion(id: string, description: string) {
  return api.put<IAssembly>(`assembly/config/addquestion/${id}`, {descricao: description});
}