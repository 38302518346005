export interface IAction {
  type: string
}

export interface ILoadingState {
  requests: number,
  loading: any
}

const INITIAL_STATE: ILoadingState = {
  requests: 0,
  loading: false
};

export default function loading(state: ILoadingState = INITIAL_STATE, action: IAction): ILoadingState {
  switch (action.type) {
    case '@loading/START_REQUEST':
      return {
        requests: state.requests + 1,
        loading: ((state.requests + 1) > 0)
      }
    case '@loading/FINISH_REQUEST':
      return {
        requests: state.requests - 1,
        loading: ((state.requests - 1) > 0),
      }
    default: 
      return state;
  }
}