import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { me, putMe } from '../../services/user';
import Spacer from '../../components/Spacer';
import toastHelper from '../../helpers/ToastHelper/ToastHelper';
import { Button, Grid, TextField } from '@material-ui/core';
import { isValidEmail, isValidPhone } from '../../util/validate/validateUtil';
import ToastHelper from '../../helpers/ToastHelper/ToastHelper';
import { showModal } from '../../components/Dialog';
import { phoneMask } from '../../util/mask/maskUtil'

interface IData {
    id: number
    cnpjcpf: string,
    nome: string,
    fone: string,
    endereco: string,
    numero: string,
    bairro: string,
    cidade: string,
    estado: string,
    email: string
}

export default function MyData() {
  const [data, setData] = useState<IData>();
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);

  const refreshData = function() {
    me().then((data) => {
      setData({...data.data, fone: phoneMask(data.data.fone)});
    }).catch(() => {
      toastHelper.error('Erro ao obter dados do sócio!');
    })
  }

  useEffect(() => {
    refreshData();
  }, []);

  const handleSendData = function()  {
    let error = false;
    let errorList = [];
    if (data && data.email && !isValidEmail(data.email)) {
      errorList.push('EMail inválido!');
      setEmailError(true);
      error = true;
    }
    if (data && data.fone && !isValidPhone(data.fone)) {
      errorList.push('Telefone inválido!');
      setPhoneError(true);
      error = true;
    }

    if (error) {
      ToastHelper.error(errorList.join('\n'));
      return;
    } else {
      setEmailError(false);
      setPhoneError(false);
    }

    const payload = {
      user: data?.cnpjcpf,
      email: data?.email,
      phone: data?.fone
    }
    putMe(payload).then(resultData => {
      if (resultData.data.error) {
        showModal({
          title: 'Atenção',
          message: resultData.data?.message || 'Erro ao alterar dados do sócio.'
        });
        return;
      }
      
      ToastHelper.success('Dados alterados com sucesso.');
      refreshData();
    }).catch(data => {
      ToastHelper.error(data.response?.data?.message || 'Erro ao alterar dados do sócio.');
    });
  }

  return (
    <>
      <Typography component="h1" variant="h5">
        Meus Dados
      </Typography>
      <Spacer />

      {data ?
      <Grid container spacing={1}>
        <Grid item xs={8}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="nome"
            disabled={true}
            label="Nome do sócio"
            name="nome"
            autoFocus
            value={data.nome}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            variant="outlined"
            margin="normal"
            disabled={true}
            required
            fullWidth
            id="cpf"
            label="CPF do sócio"
            name="cpf"
            autoFocus
            value={data.cnpjcpf}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="EMail do sócio"
            name="email"
            autoFocus
            value={data.email}
            onChange={(e) => setData({...data, email: e.target.value})}
            error={emailError}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="phone"
            label="Telefone do sócio"
            name="phone"
            autoFocus
            value={data.fone}
            onChange={(e) => setData({...data, fone: phoneMask(e.target.value)})}
            error={phoneError}
          />
        </Grid>

        <Button
          type="button"
          fullWidth
          variant="contained"
          color="primary"
          onClick={handleSendData} >
            Salvar
        </Button>
      </Grid>
      :
      <>
        Carregando dados do usuário
      </>} 
    </>
  );
}