import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  spacer: {
    height: theme.spacing(2)
  },
}));

export default function Spacer() {
  const classes = useStyles();

  return (
    <div className={classes.spacer} />
  );
}